import React, { useState } from 'react';
import { Modal, Button, Input, message, Spin } from 'antd';
import './GstModal.css'; // Import the CSS file
import axios from 'axios';
import Lottie from 'react-lottie';
import connectLottie from '../../../assets/animation.json';
import DoneIcon from '../../../assets/doneIllustration.svg';

const GstModal = ({ visible, onClose, gstin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [messageApi, contextHolder] = message.useMessage();
  const [errorStatus, setErrorStatus] = useState(null); // State to track error status
  const [validationSuccessful, setValidationSuccessful] = useState(false);


  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: connectLottie,
  };

  const handleAuthenticate = async () => {
    setLoading(true);
    try {
      // First API call
      const response = await axios.post('https://vendor-score.finkraftai.com/api/addcreds', {
        Gstin: gstin,
        Username: username,
        Password: password,
      });

      if (response.status === 200) {
        // message.success('Authentication successful!');
        // Call the second API on successful first API response
        await handleSecondApi(username, password);
      }
    } catch (error) {
      setLoading(false);
      message.error('Authentication failed. Please try again.');
      // alert('Authentication failed. Please try again.');
    }
  };

  const handleSecondApi = async (username, password) => {
    setLoading(true); // Set loading state when calling the second API
    try {
      // Second API call
      const loginResponse = await axios.post('https://scraper-apis.finkraftai.com/login', {
        username,
        password,
      });

      console.log("login response is:", loginResponse);

      handleLoginResponse(loginResponse);
      if (loginResponse.status === 200) {
        console.log("valid ")
      }
      else {
        messageApi.open({
          type: 'error',
          content: 'Invalid credentials',
        });
        console.log('Invalid credentials at try');
      }
    } catch (error) {
      setLoading(false); // Stop loading on error
      const status = error.response?.status;

      setErrorStatus(status); // Set error status
      if (status === 400) {
        // Show specific message for 400 error
        setErrorMessage('Invalid Credentials. Please check your credentials and try again.');
        messageApi.open({
          type: 'error',
          content: 'Invalid credentials',
        });
        console.log('Invalid credentials at catch');
      } else if (status === 504) {
        // Show message for 504 error
        setErrorMessage('Bad Gateway. Please try again.');
        messageApi.open({
          type: 'error',
          content: 'Bad Gateway. Please try again.',
        });
      } else {
        setErrorMessage('Something went wrong. Please try again later.');
        messageApi.open({
          type: 'error',
          content: 'Something went wrong. Please try again later.',
        });
      }
      setErrorModalVisible(true); // Show error modal
    }
  };

  const handleLoginResponse = (response) => {
    setLoading(false);
    if (response.status === 200) {
      // Show valid credentials message
      setValidationSuccessful(true);
      setErrorMessage('Valid credentials!');
      setErrorStatus(false); // No error status for success
      setErrorModalVisible(true);
    } else {
      // Handle other unexpected responses
      messageApi.open({
        type: 'error',
        content: 'Invalid credentials',
      });
      console.log('Invalid credentials');
      setErrorMessage('An unexpected error occurred.');
      setErrorStatus(false); // No error status for unexpected responses
      setErrorModalVisible(true);
    }
  };

  const handleRetry = () => {
    setErrorModalVisible(false); // Close the error modal
    handleSecondApi(username, password); // Retry the second API call
  };

  return (
    <>
      <Modal
        title="GST Portal Integration"
        visible={visible}
        onCancel={onClose}
        footer={null}
        maskClosable={false}
        className="custom-modal"
      >
        {loading ?
          (
            <>
              <Lottie options={lottieOptions} height={160} width={160} />
              <small style={{ color: 'lightgrey', textAlign: 'center', display: 'flex', width: '100%', justifyContent: 'center', alignContent: 'center' }}> *Please do not close the window, we are authenticating*</small>
            </>
          ) : (
            <div className="AddIntegration">

              {validationSuccessful ? (
                <>
                  <div style={{ padding: '28px' }}>
                    <img
                      src={DoneIcon}
                      alt="validation done"
                      style={{ width: 95 }}
                    />
                  </div>
                  <div style={{ textAlign: 'center', color: '#0a8394', fontSize: '22px', fontWeight: '600' }}>
                    Credentials Validated Successfully !
                  </div>
                  <div style={{ textAlign: 'center', color: "#c4c4c4", fontSize: '13px', marginBottom: '24px', fontWeight: '400' }}>
                    🎉Awesome, Your credential has been added and we have started fetching data for you
                  </div>
                  <Button
                    onClick={onClose}
                    style={{ width: '80%', backgroundColor: '#0a8394', color: 'white' }}>
                    Close
                  </Button>
                  <div>

                  </div>
                </>
              ) : (
                <>
                  <div className="IntegrationImage">
                    <img
                      src="https://miro.medium.com/v2/resize:fit:1400/1*9mkbjH3YUUGDSWk2UXFghw.png"
                      alt="integrationImg"
                      style={{ width: 44 }}
                    />
                    <p>GST Portal Authentication</p>
                  </div>
                  <div>
                    <p>Selected GSTIN: {gstin}</p> {/* Display the selected GSTIN */}
                  </div>
                  <div className="FormBody">
                    <div className="FormGroup">
                      <Input
                        placeholder="Username"
                        style={{ height: 42, width: '100%' }}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </div>
                    <div className="FormGroup">
                      <Input
                        placeholder="Password"
                        type="password"
                        style={{ height: 42, width: '100%' }}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="FormGroup">
                      <Button
                        type="primary"
                        style={{ width: '100%', background: 'rgb(10, 131, 148)', color: 'white' }}
                        onClick={handleAuthenticate}
                      >
                        Authenticate
                      </Button>
                    </div>
                  </div>
                  <div className="footer-link">
                    <p>Or setup bulk integrations</p>
                  </div>
                </>
              )}
            </div>

          )}

      </Modal>

      {contextHolder}



    </>
  );
};

export default GstModal;
