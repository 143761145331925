import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './table.css';
import { Empty } from 'antd';

function HSNCode({ hsnData }) {
  const [gridOptions, setGridOptions] = useState({
    columnDefs: [
      { headerName: 'HSN Code', field: 'hsncd', resizable: true, sortable: true, width: 250 },
      { headerName: 'Description', field: 'gdes', resizable: true, sortable: true, width: 550 },
    ],
    rowData: [],
    defaultColDef: {
      resizable: true,
      sortable: true,
      flex: 1,
    },
    domLayout: 'autoHeight',
    headerHeight: 50,
    rowHeight: 40,
    suppressCellSelection: true,
  });

  useEffect(() => {
    // Update rowData whenever hsnData changes
    setGridOptions((prevOptions) => ({
      ...prevOptions,
      rowData: hsnData.map(item => ({
        hsncd: item.hsncd,
        gdes: item.gdes,
      })),
    }));
  }, [hsnData]); // Dependency on hsnData to re-run effect when it changes

  useEffect(() => {
    const updateGridOptions = () => {
      const updatedColumnDefs = [
        { headerName: 'HSN Code', field: 'hsncd', resizable: true, sortable: true },
        {
          headerName: 'Description',
          field: 'gdes',
          resizable: true,
          sortable: true,
          width: window.innerWidth < 600 ? 400 : 500, // Adjust column width for small screens
        },
      ];

      setGridOptions((prevOptions) => ({
        ...prevOptions,
        columnDefs: updatedColumnDefs,
      }));
    };

    window.addEventListener('resize', updateGridOptions);
    updateGridOptions(); // Call it once on mount to initialize the correct column widths

    return () => {
      window.removeEventListener('resize', updateGridOptions);
    };
  }, []);


  if (gridOptions.rowData.length === 0) {
    return (
      <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Empty description="No HSN Codes available" />
      </div>
    );
  }

  return (
    <div className="container3">
      <h2 className='table-head' style={{ color: 'hsla(187, 87%, 31%, 1)' }}>HSN Codes</h2>
      <div className="header-line"></div>
      <div className="ag-theme-alpine" style={{ width: '100%', overflowX: 'auto' }}>
        <AgGridReact
          columnDefs={gridOptions.columnDefs}
          rowData={gridOptions.rowData} // Use the dynamic rowData
          domLayout={gridOptions.domLayout}
          headerHeight={gridOptions.headerHeight}
          rowHeight={gridOptions.rowHeight}
          suppressCellSelection={gridOptions.suppressCellSelection}
        />
      </div>
    </div>
  );
}

export default HSNCode;
