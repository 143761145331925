import APLogo from "../../assets/states/AP.png";
import ARLogo from "../../assets/states/AR.png";
import ASLogo from "../../assets/states/AS.png";
import BRLogo from "../../assets/states/BR.png";
import CGLogo from "../../assets/states/CG.png";
import GALogo from "../../assets/states/GA.png";
import GJLogo from "../../assets/states/GJ.png";
import HRLogo from "../../assets/states/HR.png";
import HPLogo from "../../assets/states/HP.png";
import JKLogo from "../../assets/states/JK.png";
import JHLogo from "../../assets/states/JH.png";
import KALogo from "../../assets/states/KA.png";
import KLLogo from "../../assets/states/KL.png";
import MPLogo from "../../assets/states/MP.png";
import MHLogo from "../../assets/states/MH.png";
import MNLogo from "../../assets/states/MN.png";
import MLLogo from "../../assets/states/ML.png";
import MZLogo from "../../assets/states/MZ.png";
import NLLogo from "../../assets/states/NL.png";
import ORLogo from "../../assets/states/OR.png";
import PBLogo from "../../assets/states/PB.png";
import RJLogo from "../../assets/states/RJ.png";
import SKLogo from "../../assets/states/SK.png";
import TNLogo from "../../assets/states/TN.png";
import TSLogo from "../../assets/states/TS.png";
import TRLogo from "../../assets/states/TR.png";
import UPLogo from "../../assets/states/UP.png";
import UKLogo from "../../assets/states/UK.png";
import WBLogo from "../../assets/states/WB.png";
import NALogo from "../../assets/states/NA.png";
import DLLogo from "../../assets/states/DL.png";
import ANLogo from "../../assets/states/AN.png";
import LALogo from "../../assets/states/LA.png";

export const STATE_GSTIN_ICON = {
  "37": APLogo, // Andhra Pradesh
  "12": ARLogo, // Arunachal Pradesh
  "18": ASLogo, // Assam
  "10": BRLogo, // Bihar
  "22": CGLogo, // Chhattisgarh
  "30": GALogo, // Goa
  "24": GJLogo, // Gujarat
  "06": HRLogo, // Haryana
  "07": DLLogo,
  "02": HPLogo, // Himachal Pradesh
  "01": JKLogo, // Jammu & Kashmir
  "20": JHLogo, // Jharkhand
  "29": KALogo, // Karnataka
  "32": KLLogo, // Kerala
  "23": MPLogo, // Madhya Pradesh
  "27": MHLogo, // Maharashtra
  "14": MNLogo, // Manipur
  "17": MLLogo, // Meghalaya
  "15": MZLogo, // Mizoram
  "13": NLLogo, // Nagaland
  "21": ORLogo, // Odisha
  "03": PBLogo, // Punjab
  "08": RJLogo, // Rajasthan
  "11": SKLogo, // Sikkim
  "33": TNLogo, // Tamil Nadu
  "36": TSLogo, // Telangana
  "16": TRLogo, // Tripura
  "09": UPLogo, // Uttar Pradesh
  "05": UKLogo, // Uttarakhand
  "19": WBLogo, // West Bengal
  "38": LALogo,
  "35": ANLogo,

  NA: NALogo,
};