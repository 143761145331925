import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './table1.css';
import GstModal from './GstModal';
import { STATE_GSTIN_ICON } from '../../../assets/states/stateConfig'
import { Empty } from 'antd';

// Function to map state codes to state names and their abbreviations
const stateMap = {
  '01': { fullName: 'Jammu & Kashmir', abbreviation: 'JK' },
  '02': { fullName: 'Himachal Pradesh', abbreviation: 'HP' },
  '03': { fullName: 'Punjab', abbreviation: 'PB' },
  '04': { fullName: 'Chandigarh', abbreviation: 'CH' },
  '05': { fullName: 'Uttarakhand', abbreviation: 'UK' },
  '06': { fullName: 'Haryana', abbreviation: 'HR' },
  '07': { fullName: 'Delhi', abbreviation: 'DL' },
  '08': { fullName: 'Rajasthan', abbreviation: 'RJ' },
  '09': { fullName: 'Uttar Pradesh', abbreviation: 'UP' },
  '10': { fullName: 'Bihar', abbreviation: 'BR' },
  '11': { fullName: 'Sikkim', abbreviation: 'SK' },
  '12': { fullName: 'Arunachal Pradesh', abbreviation: 'AR' },
  '13': { fullName: 'Nagaland', abbreviation: 'NL' },
  '14': { fullName: 'Manipur', abbreviation: 'MN' },
  '15': { fullName: 'Mizoram', abbreviation: 'MZ' },
  '16': { fullName: 'Tripura', abbreviation: 'TR' },
  '17': { fullName: 'Meghalaya', abbreviation: 'ML' },
  '18': { fullName: 'Assam', abbreviation: 'AS' },
  '19': { fullName: 'West Bengal', abbreviation: 'WB' },
  '20': { fullName: 'Jharkhand', abbreviation: 'JH' },
  '21': { fullName: 'Odisha', abbreviation: 'OD' },
  '22': { fullName: 'Chhattisgarh', abbreviation: 'CT' },
  '23': { fullName: 'Madhya Pradesh', abbreviation: 'MP' },
  '24': { fullName: 'Gujarat', abbreviation: 'GJ' },
  '27': { fullName: 'Maharashtra', abbreviation: 'MH' },
  '29': { fullName: 'Karnataka', abbreviation: 'KA' },
  '30': { fullName: 'Goa', abbreviation: 'GA' },
  '31': { fullName: 'Lakshadweep', abbreviation: 'LD' },
  '32': { fullName: 'Kerala', abbreviation: 'KL' },
  '33': { fullName: 'Tamil Nadu', abbreviation: 'TN' },
  '34': { fullName: 'Puducherry', abbreviation: 'PY' },
  '35': { fullName: 'Andaman & Nicobar Islands', abbreviation: 'AN' },
  '36': { fullName: 'Telangana', abbreviation: 'TG' },
  '37': { fullName: 'Andhra Pradesh', abbreviation: 'AP' },  // Your focus state
  '38': { fullName: 'Ladakh', abbreviation: 'LA' },
  '26': { fullName: 'Dadra and Nagar Haveli and Daman and Diu', abbreviation: 'DN' },
  '97': { fullName: 'Other Territory', abbreviation: 'OT' },
  '99': { fullName: 'Centre Jurisdiction', abbreviation: 'CJ' },
};

const GSTTable = ({ gstinData, onGstinSelect }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedGstin, setSelectedGstin] = useState(null); // State for selected GSTIN

  // Map GSTINs to states using the stateMap
  const rowData = gstinData.map(gstin => ({
    stateCode: gstin.substring(0, 2),
    gstin,
    state: stateMap[gstin.slice(0, 2)]?.fullName || 'NA',
    abbreviation: stateMap[gstin.slice(0, 2)]?.abbreviation || 'NA'
  }));

  const columnDefs = [
    {
      headerName: '',
      field: 'stateCode',
      width: 70,

      cellRenderer: (params) => {
        return <img src={STATE_GSTIN_ICON[params.data.stateCode]} alt={params.data.stateCode} style={{ height: 28, width: 28 }} />
      }
    },
    {
      headerName: 'GSTIN Number',
      field: 'gstin',
      resizable: true,
      sortable: true,
      flex: 1.8,
      cellRenderer: (params) => {
        const gstin = params.data.gstin;

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{gstin}</span>
          </div>
        );
      },
    },
    { headerName: 'State', field: 'state', resizable: true, sortable: true, flex: 1 }
  ];

  const onRowClicked = (params) => {
    const gstin = params.data.gstin;
    setSelectedGstin(gstin);
    if (onGstinSelect) {
      onGstinSelect(gstin);
    }
  };

  const getResponsiveStyles = () => {
    const defaultStyle = {
      height: '350px',
      width: '100%',
      maxWidth: '1200px',
      overflowX: 'auto',
      margin: '0 auto',
    };

    if (window.innerWidth < 600) {
      return {
        ...defaultStyle,
        height: '250px',
        maxWidth: '100%',
      };
    }

    if (window.innerWidth < 1200) {
      return {
        ...defaultStyle,
        maxWidth: '100%',
      };
    }

    return defaultStyle;
  };

  const getRowStyle = (params) => {
    if (params.data.gstin === selectedGstin) {
      return { fontWeight: 'bold' };
    }
    return null;
  };

  const getRowClass = (params) => {
    return params.data.gstin === selectedGstin ? 'bold-row' : '';
  };

  const { height, width, maxWidth, overflowX, margin } = getResponsiveStyles();

  if (rowData.length === 0) {
    return (
      <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Empty description="No GSTIN data available" />
      </div>
    );
  }

  return (
    <div className="container">
      <div className="ag-theme-alpine" style={{ height, width, maxWidth, overflowX, margin }}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          domLayout="autoHeight"
          headerHeight={50}
          rowHeight={50}
          suppressCellSelection={true}
          className="ag-grid-container"
          onRowClicked={onRowClicked}
          getRowStyle={getRowStyle}
          getRowClass={getRowClass}
          rowSelection="single"
        />
      </div>

      {/* Modal Section */}
      {isModalVisible && (
        <GstModal
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          gstin={selectedGstin}
        />
      )}
    </div>
  );
};

export default GSTTable;
