// import logo from './logo.svg';
import './App.css';

// import MainContent from "./pages/demo/MainContent/MainContent";
import MainContent from "./pages/demo/MainContent/MainContent";
import Sidebar from "./pages/demo/Sidebar/Sidebar";

function App() {
  return (
    <div className="App">
      {/* <MainContent className="main"/> */}
      <div className="sidebar-main">
        <Sidebar/>
      </div>
      <div className='main-content'>
        <MainContent/>
      </div>
      {/* <Card/> */}
    </div>
  );
}

export default App;
